<template>
  <a-layout style="padding: 0px; height: 100%; overflow: auto">
    <a-layout-content
      style="
        background-color: #fff;
        line-height: 60px;
        margin: 0;
        width: 100%;
        height: 60px;
        padding-left: 24px;
      "
    >
      <div class="Enter-top">
        <div>
          <div>
            <a-breadcrumb style="margin: 18px 0">
              <a-breadcrumb-item>工作台</a-breadcrumb-item>
              <a-breadcrumb-item>系统设置</a-breadcrumb-item>
              <a-breadcrumb-item>个人设置</a-breadcrumb-item>
            </a-breadcrumb>
          </div>
        </div>
      </div>
    </a-layout-content>
    <a-layout style="padding: 24px; height: 100%; overflow: auto">
      <a-layout-content
        :style="{ background: '#fff', padding: '24px', margin: 0 }"
      >
        <a-form-model
          style="width: 630px; margin: 0 auto"
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <div style="position: relative">
            <a-form-model-item label="姓名">
              <a-input v-model="form.name" />
            </a-form-model-item>
            <a-form-model-item
              style="position: absolute; right: 50px; top: 0px; z-index: 100"
              label="头像"
            >
              <div class="clearfix">
                <a-upload
                  action=""
                  list-type="picture-card"
                  :file-list="logo"
                  @preview="handlePreview"
                  :customRequest="handleChange"
                  @change="handleChanges"
                >
                  <div v-if="logo.length < 1">
                    <a-icon type="plus" />
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <a-modal
                  :visible="previewVisible"
                  :footer="null"
                  @cancel="handleCancel"
                >
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </div>
            </a-form-model-item>
          </div>
          <a-form-model-item label="性别">
            <a-radio-group v-model="form.gender">
              <a-radio :value="0"> 未知 </a-radio>
              <a-radio :value="1"> 男 </a-radio>
              <a-radio :value="2"> 女 </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="手机号">
            <a-input disabled="true" v-model="form.mobile" />
          </a-form-model-item>
          <a-form-model-item label="邮箱">
            <a-input disabled="true" v-model="form.email" />
          </a-form-model-item>
          <a-form-item :wrapper-col="{ span: 10, offset: 0 }">
            <a-button type="primary" @click="onSubmit"> 更新信息 </a-button>
          </a-form-item>
        </a-form-model>
      </a-layout-content>
    </a-layout>
  </a-layout>
</template>
<script>
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
import http from '../../../http'
const OSS = require('ali-oss')
let client
import { nanoid } from 'nanoid'
export default {
  data() {
    return {
      res: {
        credit: {
          securityToken: '',
          accessKeySecret: '',
          accessKeyId: '',
        },
        region: '',
      },
      logo: [],
      loading: false,
      imageUrl: '',
      fill: null,
      labelCol: { span: 11 },
      wrapperCol: { span: 14 },
      form: {
        avatar: '',
        gender: '',
        name: '',
        email: '',
        mobile: '',
      },
    }
  },
  methods: {
    onClose() {
      this.$router.back()
    },
    onSubmit() {
      //   console.log(this.form);
      this.putuser()
    },
    async getuser() {
      try {
        const res = await http.getuser()
        const { success, data } = res.data
        if (success) {
          //   console.log(data);
          this.form = {
            gender: data.user.gender,
            name: data.user.name,
            email: data.user.email,
            mobile: data.user.mobile,
          }
          if (data.user.avatar == null || data.user.avatar == '[]') {
            this.form.avatar = data.user.avatar
          } else {
            let logos = JSON.parse(data.user.avatar)
            setTimeout(() => {
              logos.forEach(item => {
                // console.log(item.name);
                this.logo = [
                  {
                    bucket: 'sccncloud2',
                    name: item.name,
                    uid: nanoid(),
                    status: 'done',
                    url: client.signatureUrl(item.name),
                  },
                ]
              })
            }, 100)
            this.form.avatar = JSON.stringify(this.logo)
          }
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    async putuser() {
      try {
        const res = await http.putuser(this.form)
        const { success, msg } = res.data
        if (success) {
          //   console.log(data);
          this.$message.success('操作成功')
        } else {
          this.$message.success(msg)
        }
      } catch (ex) {
        console.log('error:' + ex)
      }
    },
    // 图片
    async handlePreview(file) {
      //   console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    async getsts() {
      try {
        const res = await http.getsts()
        // console.log(res);
        this.res.credit.securityToken = res.data.data.credit.securityToken
        this.res.credit.accessKeySecret = res.data.data.credit.accessKeySecret
        this.res.credit.accessKeyId = res.data.data.credit.accessKeyId
        this.res.region = res.data.data.region
        client = new OSS({
          region: this.res.region,
          accessKeyId: this.res.credit.accessKeyId,
          accessKeySecret: this.res.credit.accessKeySecret,
          stsToken: this.res.credit.securityToken,
          // 填写Bucket名称。
          bucket: 'sccncloud2',
        })
      } catch (e) {
        console.error(e)
      }
    },
    handleChanges({ fileList }) {
      if (this.logo.length > fileList.length) {
        this.logo = fileList
        this.form.avatar = JSON.stringify(this.logo)
      }
    },
    handleChange(file) {
      var data = file.file
      this.putObject(client, nanoid(), file.file.name.split('.')[1], data)
    },
    async putObject(client, id, name, data) {
      try {
        const result = await client.put(`sccn/${id}.${name}`, data)
        let url
        url = client.signatureUrl(result.name)
        var a = [result]
        a.forEach(item => {
          this.logo.push({
            bucket: 'sccncloud2',
            name: item.name,
            uid: id,
            status: 'done',
            url: url,
          })
        })
        this.form.avatar = JSON.stringify(this.logo)
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    this.getsts()
    this.getuser()
  },
}
</script>
<style scoped>
.search-wrap {
  width: 100%;
  /* background: #999; */
  border-radius: 3px;
  position: relative;
  min-height: 730px;
  height: 100%;
  padding: 10px 0 6px;
}
.flow-d-r {
  display: flex;
  flex-direction: row;
}
.ant-layout {
  height: 100%;
}
/* .ant-form-item {
  height: 60px;
  margin-bottom: 15px;
} */
::v-deep .ant-form-item label {
  margin-right: 16px;
  font-weight: 500;
}
::v-deep .ant-table .light {
  background-color: white;
}
::v-deep .ant-table .dark {
  background-color: #fafafa;
}
.ant-page-header {
  padding: 0;
}
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
::v-deep .ant-form-item-label {
  text-align: left;
  padding-left: 3px;
}
</style>
